import withConfirmation from 'components/core/withConfirmation';
import { ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_100 } from 'style/constants';
import styled from 'styled-components';
import { css } from 'styled-components';
import { IconButton } from 'components/admin2/ui/Button';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';
import { SINGLE_LINE_ELLIPSIS } from 'style/mixins';

export const Wrapper = styled.div<{
  borderStyle?: string;
  isEditing: boolean;
  keepBorder?: boolean;
}>`
  position: relative;
  border: 1px ${({ borderStyle = 'solid' }) => borderStyle} transparent;
  transition: border-color 0.3s;
  ${({ keepBorder, isEditing }) => isEditing && css`
    ${keepBorder && '&, '}&:hover, &:focus-within {
      border-color: ${ADMIN_ACCENT_PRIMARY};
      & > *:first-child {
        pointer-events: all;
        opacity: 1;
      }
    }
  `};
`;

export const ActionButton = withConfirmation(IconButton);

export const EditorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 4px;
  width: 100%;
  min-width: max-content;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  left: 0;
`;

export const BlockTitle = styled.span`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  background: ${ADMIN_ACCENT_PRIMARY};
  color: ${ADMIN_TEXT_100};
  height: 16px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  ${SINGLE_LINE_ELLIPSIS}
`;

export const EditorActions = styled.div<{ editorActionsCss?: ReturnType<typeof css> | string; }>`
  display: flex;
  gap: 4px;
  padding: 4px 0;

  ${({ editorActionsCss }) => editorActionsCss}
`;
