import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPrimaryToken } from 'services/auth';
import { useAjax } from 'hooks';
import { SITE_ID, VIDEO_SERVICE_BASE_URL } from 'config';
import {
  BackButton,
  ButtonsContainer,
  Container,
  MenuContainer,
  SaveButton,
  StyledAdminModal,
  StyledErrorLabel,
  StyledDropDown,
  StyledRadioGroup,
  StyledTextInput,
  Subtitle,
  Title,
} from './styles';
import { find } from 'lodash';
import TranslatedText from 'components/i18n/TranslatedText';
import { useAdminTranslation } from 'hooks/use-translation';

interface ITaggedVideosBlock {
  arrayId?: string;
  name: string;
  sort: string;
  tag: string;
}

interface IProps {
  data: ITaggedVideosBlock | null;
  handleAdd: (taggedVideosBlock: ITaggedVideosBlock) => void;
  handleSave: (taggedVideosBlock: ITaggedVideosBlock) => void;
  isOpen: boolean;
  onClose: () => void;
}

const SORT_OPTION = [
  { value: 'Trending', stringKey: 'TAGGED_VIDEOS_BLOCK_SORT_TRENDING_OPTION', testId: 'taggedVideoSortTrending' },
  {
    value: 'Most Views',
    stringKey: 'TAGGED_VIDEOS_BLOCK_SORT_MOST_VIEWS_OPTION',
    testId: 'taggedVideoSortMostViews',
  },
  {
    value: 'Most Recent',
    stringKey: 'TAGGED_VIDEOS_BLOCK_SORT_MOST_RECENT_OPTION',
    testId: 'taggedVideoSortMostRecent',
  },
];

const TaggedVideosEditorModal = ({
  data,
  isOpen,
  onClose,
  handleSave,
  handleAdd,
}: IProps) => {
  const { t } = useAdminTranslation();
  const [nameBlock, setNameBlock] = useState('');
  const [tag, setTag] = useState<Record<string, any> | null>(null);
  const [sort, setSort] = useState<Record<string, any>>(SORT_OPTION[0]);
  const [tagsOptions, setTagOptions] = useState<Record<string, any>[]>([]);
  const [errors, setErrors] = useState<{ name: boolean; tag: boolean }>({
    name: false,
    tag: false,
  });

  const token = useSelector(getPrimaryToken);

  useEffect(() => {
    if (data) {
      setNameBlock(data.name);
      setTag({ value: data.tag, label: data.tag });
      const sortOption =
        find(SORT_OPTION, (option) => option.value === data.sort) ||
        SORT_OPTION[0];
      setSort(sortOption);
    }
  }, []);

  const { data: siteTags, loaded } = useAjax<string[]>({
    headers: {
      authorization: `Bearer ${token}`,
      'x-maestro-client-id': SITE_ID,
    },
    method: 'GET',
    url: `${VIDEO_SERVICE_BASE_URL}/tags/site`,
  });

  useEffect(() => {
    if (loaded && siteTags) {
      const tagOptions = siteTags.map((siteTag) => ({
        value: siteTag,
        label: siteTag,
      }));
      setTagOptions(tagOptions);
    }
  }, [loaded]);

  const onHandleSave = () => {
    if (!(tag && nameBlock)) {
      setErrors({ name: !Boolean(nameBlock), tag: !Boolean(tag) });
      return null;
    }
    const toSaveTaggedVideoBlock = {
      name: nameBlock,
      tag: tag.value,
      sort: sort.value,
    };

    if (data) {
      handleSave(toSaveTaggedVideoBlock);
    } else {
      handleAdd(toSaveTaggedVideoBlock);
    }
    return;
  };

  const onHandleSetName = ((value: string)=> {
    setErrors({ ...errors, name: !Boolean(value) });
    setNameBlock(value);
  });

  const onHandleSelectTag = (selectedTag: Record<string, any>) => {
    setErrors({ ...errors, tag: !Boolean(selectedTag) });
    setTag(selectedTag);
  };

  const onHandleSelectSort = (value: Record<string, any>) => setSort(value);

  if (!isOpen) {
    return null;
  }

  return (
    <StyledAdminModal
      allowOverflow={true}
      onClose={onClose}
      maxHeight="530px"
      maxWidth="960px"
    >
      <Container>
        <Title>
          <TranslatedText stringKey="TAGGED_VIDEOS_BLOCK_TITLE" />
        </Title>
        <Subtitle>
          <TranslatedText stringKey="TAGGED_VIDEOS_BLOCK_SUBTITLE" />
        </Subtitle>
        <StyledTextInput
          labelKey="TAGGED_VIDEOS_BLOCK_NAME"
          value={nameBlock}
          onChange={onHandleSetName}
          inputTestId="taggedVideoName"
        />
        {errors.name && (
          <StyledErrorLabel
            admin={true}
            labelKey="TAGGED_VIDEOS_BLOCK_NAME_ERROR"
          />
        )}
        <MenuContainer>
          <StyledDropDown
            labelKey="TAGGED_VIDEOS_BLOCK_TAG"
            onChange={onHandleSelectTag}
            options={tagsOptions}
            value={tag}
            placeholderKey="TAGGED_VIDEOS_BLOCK_TAG_PLACEHOLDER"
            dataTestId="taggedVideoDropdown"
            testIdSearchInput="taggedVideoDropdownInput"
          />
        </MenuContainer>
        {errors.tag && (
            <StyledErrorLabel
              admin={true}
              labelKey="TAGGED_VIDEOS_BLOCK_TAG_ERROR"
            />
        )}
        <MenuContainer>
          <StyledRadioGroup
            onChange={onHandleSelectSort}
            options={SORT_OPTION}
            selection={sort}
            labelKey="TAGGED_VIDEOS_BLOCK_SORT"
          />
        </MenuContainer>
        <ButtonsContainer>
          <BackButton onClick={onClose} data-testid="taggedVideoBackButton">{t('ADMIN_LABEL_BACK')}</BackButton>
          <SaveButton onClick={onHandleSave} data-testid="taggedVideoSaveButton">{t('ACTION_SAVE')}</SaveButton>
        </ButtonsContainer>
      </Container>
    </StyledAdminModal>
  );
};

export default TaggedVideosEditorModal;
