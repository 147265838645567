import React, { memo, ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';
import TranslatedText from 'components/i18n/TranslatedText';
import Label from 'components/admin2/ui/Label';
import { INPUT_LENGTH } from 'style/mixins';
import ErrorMessage from 'components/admin2/ui/ErrorMessage';
import { TYPES } from './constants';
import { InputType } from 'zlib';
import type { TranslationKey } from 'hooks/use-translation';
import { HTTPS_PREFIX } from '../../../hooks/use-link-input-validation';
import { Wrapper, Input, ButtonWrapper, Container, FormContainer, PrefixInput, SearchIcon, PrefixIcon, HTTPSPrefixInput } from './styles';
import { HTTPS_PREFIX_ID } from 'global-ids';

export { TYPES };

export const CharsLengthHelper = styled.span`${INPUT_LENGTH};`;

export interface ITextInputProps {
  autoFocus?: boolean;
  children?: string | React.ReactNode;
  className?: string;
  'data-testid'?: string;
  description?: string | React.ReactNode;
  descriptionKey?: TranslationKey;
  disabled?: boolean;
  errorKey?: TranslationKey;
  hasButton?: boolean;
  hasError?: boolean;
  inputBorder?: string;
  inputId?: string;
  inputTestId?: string;
  inputTooltip?: string;
  inputType?: InputType;
  isRequired?: boolean;
  labelHintKey?: TranslationKey;
  labelIcon?: string;
  labelIconSize?: number;
  labelKey?: TranslationKey;
  labelPadding?: string;
  labelTextSize?: string;
  labelWhiteIcon?: boolean;
  maxlength?: number;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  padding?: string;
  placeholderKey?: TranslationKey;
  placeholderText?: string;
  prefixInputIcon?: string;
  readOnly?: boolean;
  setInputRef?: React.RefObject<HTMLInputElement>;
  showDangerErrorMessage?: boolean;
  stretch?: boolean;
  type?: string;
  value?: string | number;
}

function TextInput ({
  inputId,
  children,
  autoFocus,
  className = '',
  'data-testid': testId,
  description,
  descriptionKey,
  disabled = false,
  errorKey ='ADMIN_LABEL_REQUIRED',
  hasButton = false,
  hasError,
  inputBorder = '1px solid transparent',
  inputTestId,
  inputType = TYPES.TEXT,
  inputTooltip,
  isRequired = false,
  labelHintKey,
  labelIcon,
  labelIconSize,
  labelKey,
  labelPadding,
  labelTextSize,
  labelWhiteIcon = false,
  maxlength,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  padding,
  placeholderKey,
  placeholderText,
  prefixInputIcon,
  readOnly = false,
  showDangerErrorMessage = false,
  stretch = false,
  type = 'text',
  setInputRef,
  value,
}: ITextInputProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event.target.value);
  };

  const renderWithButton = useCallback((text?: string) => {
    return (
      <Wrapper>
        <Input
          data-testid={inputTestId}
          disabled={disabled}
          inputType={inputType}
          maxLength={maxlength}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={onFocus}
          autoFocus={autoFocus}
          placeholder={text}
          prefixInputIcon={prefixInputIcon}
          readOnly={readOnly}
          showDangerErrorMessage={showDangerErrorMessage}
          type={type}
          tooltip={inputTooltip}
          value={value}
          onKeyDown={onKeyDown}
        />
        <ButtonWrapper>
          {children}
        </ButtonWrapper>
      </Wrapper>
    );
  }, [
    hasButton, children, inputTestId, disabled, inputType,
    handleBlur, handleChange, onFocus, prefixInputIcon, readOnly,
    showDangerErrorMessage, type, inputTooltip, value, onKeyDown,
  ]);

  const renderWithPlaceholder = (text?: string) => {

    return (
      <Container className={className} data-testid={testId} padding={padding}>
        {(description || descriptionKey || labelKey || labelIcon) && (
          <Label
            description={description}
            descriptionKey={descriptionKey}
            errorKey={errorKey}
            hasError={hasError !== undefined ? hasError : isRequired && !value}
            icon={labelIcon}
            iconSize={labelIconSize}
            labelHintKey={labelHintKey}
            labelKey={labelKey}
            padding={labelPadding}
            textSize={labelTextSize}
            whiteIcon={labelWhiteIcon}
          />
        )}
        <FormContainer inputBorder={inputBorder} inputType={inputType} stretch={stretch}>
          {inputType === TYPES.SEARCH && <SearchIcon name="search" />}
          {prefixInputIcon && prefixInputIcon !== 'linkOutline' && (
            <PrefixInput>
              <PrefixIcon name={prefixInputIcon} />
            </PrefixInput>
          )}
          {prefixInputIcon === 'linkOutline' && (
            <HTTPSPrefixInput id={HTTPS_PREFIX_ID}>
              {HTTPS_PREFIX}
            </HTTPSPrefixInput>
          )}
          {hasButton ? renderWithButton(text) : (
            <Input
              id={inputId}
              ref={setInputRef}
              data-testid={inputTestId}
              disabled={disabled}
              inputType={inputType}
              maxLength={maxlength}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={onFocus}
              placeholder={text}
              prefixInputIcon={prefixInputIcon}
              readOnly={readOnly}
              showDangerErrorMessage={showDangerErrorMessage}
              type={type}
              tooltip={inputTooltip}
              value={value}
              onKeyDown={onKeyDown}
            />
          )}
          {maxlength && (
            <CharsLengthHelper>
              {typeof value === 'string' ? value.length : 0}/{maxlength}
            </CharsLengthHelper>
          )}
        </FormContainer>
        {showDangerErrorMessage && <ErrorMessage errorMessageKey={errorKey} />}
      </Container>
    );
  };

  if (placeholderText || !placeholderKey) {
    return renderWithPlaceholder(placeholderText || undefined);
  }
  return (
    <TranslatedText
      stringKey={placeholderKey}
    >
      {(text) => renderWithPlaceholder(text)}
    </TranslatedText>
  );
}

export default memo(TextInput);
