import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import IObject from 'models/IObject';
import IGate, { GateKind } from 'models/IGate';
import useRealtimeDocument from 'hooks/use-realtime-document';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentPageId,
  isLandingPageType,
} from 'services/app/selectors';
import {
  publishPendingChanges,
  setPendingPageDoc,
} from 'services/admin/actions';
import {
  getPendingPageDocs,
  getDraftHomeId,
  hasUnpublishedAdminChanges,
} from 'services/admin/selectors';
import PageEditor from './PageEditor';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import useDeletePage from 'hooks/use-delete-page';
import RealtimePage from 'components/core/RealtimePage';

export default function PageSettingsTab() {
  const dispatch = useDispatch();
  const pageId = useSelector(getCurrentPageId);
  const isLandingPage = useSelector(isLandingPageType);
  const pageDraftDocs = useSelector(getPendingPageDocs);
  const homeId = useSelector(getDraftHomeId);
  const hasCurrentDraft = Boolean(pageDraftDocs[pageId]);
  const canPublish = useSelector(hasUnpublishedAdminChanges) || hasCurrentDraft;
  const [homePage] = useRealtimeDocument<IObject>('objects', homeId);

  const { deleteDoc, canDelete } = useDeletePage(pageId);

  const headerKey: TI18nKey = isLandingPage ? 'ADMIN_LABEL_PAGE_SETTINGS' : 'ADMIN_LABEL_CHANNEL_SETTINGS';

  const saveChanges = (doc: IObject, channelVideoUpdate?: boolean) => {
    const id = doc?._id;
    if (!id) {
      return;
    }
    dispatch(setPendingPageDoc(id, doc));
  };

  const publishChanges = () => {
    dispatch(publishPendingChanges({ pageId }));
  };

  const getInitialDoc = (doc: IObject) => {
    const newObj: IObject = JSON.parse(JSON.stringify(doc));
    if (!doc.data.gate) {
      newObj.data.gate = { kind: GateKind.NONE } as IGate;
    }
    return newObj;
  };

  return (
    <AdminBarTab>
      <AdminBarTabHeader headerKey={headerKey} />
      <RealtimePage id={pageId}>
        {(doc, loaded) => {
          if (!loaded) {
            return <PageSpinner />;
          }

          const initialDoc = getInitialDoc(pageDraftDocs[pageId!] || doc || homePage);
          const docExists = Boolean(doc);

          return (
            <PageEditor
              canPublish={canPublish}
              docExists={docExists}
              initialDoc={initialDoc}
              onDelete={canDelete ? deleteDoc : null}
              onPublish={publishChanges}
              onSave={saveChanges}
            />
          );
        }}
      </RealtimePage>
    </AdminBarTab>
  );
}

const PageSpinner = styled(LoadingSpinner)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
