import React, { memo, useMemo } from 'react';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import ErrorBox from 'components/admin2/ui/ErrorBox';
import { css } from 'styled-components';
import { Description } from 'components/admin2/ui/Text';
import { Container, StyledIcon, StyledLabelHint, Title, TitleContainer } from './styles';
import type { TranslationKey } from 'hooks/use-translation';

export interface LabelProps {
  admin?: boolean;
  color?: string;
  compact?: boolean;
  description?: string | React.ReactNode;
  descriptionKey?: TranslationKey;
  errorKey?: TranslationKey;
  errorMsg?: string;
  hasError?: boolean;
  icon?: string;
  iconColor?: string;
  iconCss?: ReturnType<typeof css> | string;
  iconRightAlign?: boolean;
  iconSize?: number;
  iconSpacing?: number;
  label?: string;
  labelHint?: string;
  labelHintKey?: TranslationKey;
  labelKey?: TranslationKey;
  padding?: string;
  spacing?: string;
  textSize?: string;
  uppercase?: boolean;
  whiteIcon?: boolean;
}

function Label({
  admin = true,
  color,
  compact,
  description,
  descriptionKey,
  errorKey,
  errorMsg,
  hasError,
  icon,
  iconColor,
  iconCss,
  iconRightAlign,
  iconSize,
  iconSpacing,
  label,
  labelHint,
  labelHintKey,
  labelKey,
  padding,
  spacing,
  uppercase = true,
  whiteIcon,
  ...props
}: LabelProps) {
  const iconProps = useMemo(() => icon && ({
    alignRight: iconRightAlign,
    color: iconColor || color,
    name: icon,
    size: iconSize,
    spacing: iconSpacing,
    white: whiteIcon,
  }), [color, iconColor, iconSize, iconSpacing, icon, whiteIcon]);

  return (
    <Container {...props} admin={admin} color={color} spacing={spacing}>
      <TitleContainer compact={!!(description || descriptionKey || compact)} padding={padding}>
        {iconProps && !iconRightAlign ? (
          <StyledIcon {...iconProps} />
        ) : null}
        <OptionalTranslated component={Title} stringKey={labelKey}>
          {label}
        </OptionalTranslated>
        {iconProps && iconRightAlign ? (
          <StyledIcon {...iconProps} />
        ) : null}
        {labelHintKey && (
          <OptionalTranslated component={StyledLabelHint} stringKey={labelHintKey}>
            {labelHint}
          </OptionalTranslated>
        )}
      </TitleContainer>
      {(descriptionKey || description) && (
        <OptionalTranslated component={Description} stringKey={descriptionKey}>
          {description}
        </OptionalTranslated>
      )}
      {hasError && (
        <OptionalTranslated component={ErrorBox} stringKey={errorKey} uppercase={uppercase}>
          {errorMsg}
        </OptionalTranslated>
      )}
    </Container>
  );
}

export default memo(Label);
