import styled, { css } from 'styled-components';
import Expandable from 'components/ui/Expandable';
import Row from 'components/admin2/ui/Library/Row';
import {
  ADMIN_SURFACE_1,
  ADMIN_SURFACE_2,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_5,
} from 'style/constants';
import { ComponentProps } from 'react';

export interface ItemHeaderProps extends Omit<ComponentProps<typeof Row>, 'compact' | 'iconCss'> {
  headerBackground?: string;
  isExpanded?: boolean;
}

export const CollapsibleItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemHeader = styled(Row).attrs({
  compact: true,
  iconCss: `
    background: ${ADMIN_SURFACE_4};
  `,
})<ItemHeaderProps>`
  ${({ isExpanded }) => isExpanded && css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
  background: ${({ headerBackground = ADMIN_SURFACE_3 }) => headerBackground};
  &:hover {
    background: ${ADMIN_SURFACE_2};
  }
`;

export const ExpandableItemContent = styled(Expandable)`
  background: ${ADMIN_SURFACE_1};
  ${({ isExpanded }) => isExpanded && css`border-top: 1px solid ${ADMIN_SURFACE_5}`};
  border-radius: 0 0 5px 5px;
`;
