import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { questColumns } from 'components/admin2/MediaLibrary/constants';
import LibraryModal from 'components/admin2/LibraryModal';
import AdminModal from 'components/admin2/ui/AdminModal';
import QuestEditor from 'components/admin2/QuestEditor';
import { QUEST_BASE_URL } from 'config';
import { camelify } from 'shared/string-utils';

const NEW_DOC = {};

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
};

export default function QuestLibraryModalView({
  onClose,
  onSelectItem,
}) {
  const [editingQuest, setEditingQuest] = useState(null);

  const buttonProps = useMemo(() => ({
    labelKey: 'ADMIN_ACTION_ADD_QUEST',
    onClick: () => setEditingQuest(NEW_DOC),
  }), []);

  const onAfterSave = useCallback(() => (
    setEditingQuest(null)
  ), []);

  const selectItem = useCallback((item) => {
    onSelectItem(item._id);
    onClose();
  }, [
    onSelectItem,
    onClose,
  ]);

  const editItem = useCallback((item) => {
    setEditingQuest(camelify(item));
  }, []);

  const onBack = useCallback(() => {
    setEditingQuest(null);
  }, []);

  const onAdminModalClose = useCallback(() => {
    setEditingQuest(null);
  }, []);

  return (
    <>
      <LibraryModal
        buttonProps={buttonProps}
        columns={questColumns}
        fetchObject={{
          service: QUEST_BASE_URL,
        }}
        helperText="ADMIN_LABEL_QUESTS_DESCRIPTION"
        onClose={onClose}
        onEditItem={editItem}
        onSelectItem={selectItem}
        searchPlaceholderKey="ADMIN_PLACEHOLDER_SEARCH_FOR_A_QUEST"
        titleKey="ADMIN_PANEL_QUEST_NAME"
      />
      { editingQuest && (
        <AdminModal
          fixedHeight
          maxWidth="800px"
          onClose={onAdminModalClose}
        >
          <QuestEditor
            // TODO: "Back to Library" when created from Library view
            // backButtonKey=""
            initialValue={editingQuest === NEW_DOC ? undefined : editingQuest}
            onAdd={selectItem}
            onAfterSave={onAfterSave}
            onBack={onBack}
          />
        </AdminModal>
      )}
    </>
  );
}

QuestLibraryModalView.propTypes = propTypes;
