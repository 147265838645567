export const removeEmptyFields = (obj: any): any => {
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmptyFields(obj[key]);
    }
  }
  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (value == null || (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && Object.keys(value).length === 0)) {
      delete obj[key];
    }
  });
  return obj;
};
