import React from 'react';
import { MAESTRO_BLACK, TRANSPARENT_COLOR } from 'style/constants';
import { ColorPickerIcon, ColorsWrapper, TransparentIcon } from './styles';
import ColorOption from './ColorOption';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';

interface IColorOptionsProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  colorPickerBackgroundColor?: string;
  colorPickerOptionColor?: string;
  colorPickerTitleKey: TI18nKey;
  colors: string[];
  enableColorPicker?: boolean;
  enableTransparent?: boolean;
  onChange: (color: string) => void;
  selectedColor: string;
}

const ColorOptions: React.FC<IColorOptionsProps> = ({
  colorPickerBackgroundColor,
  enableTransparent = false,
  enableColorPicker = false,
  colorPickerTitleKey,
  colors,
  selectedColor,
  onChange,
  colorPickerOptionColor,
}) => {
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const colorPickerParentRef = React.useRef(null);

  const isCustomColor = !colors.includes(selectedColor) && selectedColor !== TRANSPARENT_COLOR;

  const handleSelectColor = (targetColor: string) => () => {
    if (targetColor !== selectedColor) {
      onChange(targetColor);
    }
  };

  const handleOpenColorPicker = () => {
    setColorPickerOpen(true);
  };

  const handleCloseColorPicker = () => {
    setColorPickerOpen(false);
  };

  const options = React.useMemo(() => {
    return colors.map((color, index) => {
      return (
        <ColorOption
          key={index}
          onClick={handleSelectColor(color)}
          selected={selectedColor === color}
          color={color}
        />
      );
    });
  }, [selectedColor, colors, colorPickerOpen]);

  return (
    <>
      <ColorsWrapper>
        {enableTransparent && (
          <ColorOption
            onClick={handleSelectColor(TRANSPARENT_COLOR)}
            selected={selectedColor === TRANSPARENT_COLOR}
            color={TRANSPARENT_COLOR}
          >
            <TransparentIcon name="transparentCircled" />
          </ColorOption>
        )}
        {options}
        {enableColorPicker && (
          <ColorOption
            colorPicker={{
              color: colorPickerOptionColor || selectedColor,
              isOpen: colorPickerOpen,
              onClose: handleCloseColorPicker,
              onResult: onChange,
              parentRef: colorPickerParentRef,
              titleKey: colorPickerTitleKey,
              backgroundColor: colorPickerBackgroundColor,
              useModalPortal: true,
            }}
            colorOptionRef={colorPickerParentRef}
            selected={isCustomColor}
            onClick={handleOpenColorPicker}
            color={colorPickerOpen ? selectedColor : (colorPickerOptionColor || MAESTRO_BLACK)}
          >
            <ColorPickerIcon name="colorPicker" />
          </ColorOption>
        )}
      </ColorsWrapper>
    </>
  );
};

export default ColorOptions;
