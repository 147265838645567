// action editor configurations for each action type
import {
  ANSWER_ANY_POLL,
  ANSWER_ANY_PREDICTION,
  ANSWER_ANY_PREDICTION_CORRECTLY,
  ANSWER_ANY_TRIVIA,
  ANSWER_ANY_TRIVIA_CORRECTLY,
  ANSWER_SPECIFIC_POLL,
  ANSWER_SPECIFIC_PREDICTION,
  ANSWER_SPECIFIC_PREDICTION_CORRECTLY,
  ANSWER_SPECIFIC_TRIVIA,
  ANSWER_SPECIFIC_TRIVIA_CORRECTLY,
  IMPOSSIBLE,
  OPEN_LINK,
  RETWEET,
  SHARE_FACEBOOK,
  SEA_OF_THIEVES_VISIT_ISLAND_ID,
  SEA_OF_THIEVES_KILL_SKELETON_ID,
  SEA_OF_THIEVES_KILL_PLAYER_ID,
  VIEW_PANEL,
  VISIT_CHANNEL,
  VISIT_TIME,
  WATCH_ANY_VIDEO,
  WATCH_SPECIFIC_VIDEO,
} from 'components/objects/Quest/QuestAction/constants';

import { COMPONENT_TYPES } from '../constants';

const POLL = 'poll';
const PREDICTION = 'prediction';
const TRIVIA = 'trivia';

const labelKeys = {
  [POLL]: 'ADMIN_LABEL_POLL',
  [PREDICTION]: 'ADMIN_LABEL_PREDICTION',
  [SEA_OF_THIEVES_KILL_PLAYER_ID]: 'ADMIN_LABEL_QUEST_SEA_OF_THIEVES_PLAYER_KILLS',
  [SEA_OF_THIEVES_KILL_SKELETON_ID]: 'ADMIN_LABEL_QUEST_SEA_OF_THIEVES_SKELETON_KILLS',
  [SEA_OF_THIEVES_VISIT_ISLAND_ID]: 'ADMIN_LABEL_QUEST_SEA_OF_THIEVES_ISLAND_VISITS',
  [TRIVIA]: 'ADMIN_LABEL_TRIVIA',
};

// quest action properties
const BODY = 'body'; // text for the name of the action
const VALUE_REQUIRED = 'valueRequired'; // how many times the action needs to be completed (repeat)
const VALIDATION_VALUE = 'validationValue'; // any additional value used, e.g. poll id, link url
const CHANNEL_ID = 'channelId'; // for actions that need to be completed on a channel
const TIME_MAXIMUM = 'timeMaximum'; // time window a quest action can satisfied by user action
const TIME_MINIMUM = 'timeMinimum';

const anyMultipleChoiceConfig = () => ([
  {
    componentKey: COMPONENT_TYPES.TEXT_INPUT,
    isRequired: true,
    labelKey: 'ADMIN_LABEL_TEXT',
    valueKey: BODY,
  },
  {
    componentKey: COMPONENT_TYPES.TEXT_INPUT,
    labelKey: 'ADMIN_LABEL_REQUIRED_AMOUNT',
    type: 'number',
    valueKey: VALUE_REQUIRED,
  },
  {
    componentKey: COMPONENT_TYPES.CHANNEL_SELECT,
    valueKey: CHANNEL_ID,
  },
]);

const specificMultipleChoiceConfig = typeKey => ([
  {
    componentKey: COMPONENT_TYPES.TEXT_INPUT,
    isRequired: true,
    labelKey: 'ADMIN_LABEL_TEXT',
    valueKey: BODY,
  },
  {
    componentKey: COMPONENT_TYPES.MULTI_CHOICE_SELECT,
    labelKey: labelKeys[typeKey],
    typeKey,
    valueKey: VALIDATION_VALUE,
  },
  {
    componentKey: COMPONENT_TYPES.LINK_TEXT,
  },
  {
    componentKey: COMPONENT_TYPES.CHANNEL_SELECT,
    valueKey: CHANNEL_ID,
  },
]);

const seaOfThievesConfig = type => ([
  {
    componentKey: COMPONENT_TYPES.TEXT_INPUT,
    labelKey: 'ADMIN_LABEL_TEXT',
    valueKey: BODY,
  },
  {
    componentKey: COMPONENT_TYPES.TEXT_INPUT,
    labelKey: labelKeys[type],
    type: 'number',
    valueKey: VALUE_REQUIRED,
  },
  {
    componentKey: COMPONENT_TYPES.CHANNEL_SELECT,
    valueKey: CHANNEL_ID,
  },
]);

const templates = {
  [ANSWER_ANY_POLL]: anyMultipleChoiceConfig(),
  [ANSWER_ANY_PREDICTION]: anyMultipleChoiceConfig(),
  [ANSWER_ANY_PREDICTION_CORRECTLY]: anyMultipleChoiceConfig(),
  [ANSWER_ANY_TRIVIA]: anyMultipleChoiceConfig(),
  [ANSWER_ANY_TRIVIA_CORRECTLY]: anyMultipleChoiceConfig(),
  [ANSWER_SPECIFIC_POLL]: specificMultipleChoiceConfig(POLL),
  [ANSWER_SPECIFIC_PREDICTION]: specificMultipleChoiceConfig(PREDICTION),
  [ANSWER_SPECIFIC_PREDICTION_CORRECTLY]: specificMultipleChoiceConfig(PREDICTION),
  [ANSWER_SPECIFIC_TRIVIA]: specificMultipleChoiceConfig(TRIVIA),
  [ANSWER_SPECIFIC_TRIVIA_CORRECTLY]: specificMultipleChoiceConfig(TRIVIA),
  [IMPOSSIBLE]: [{
    componentKey: COMPONENT_TYPES.TEXT_INPUT,
    helperKey: 'ADMIN_LABEL_CHANNEL_SPECIFIC_HELPER',
    isRequired: true,
    labelKey: 'ADMIN_LABEL_TEXT',
    valueKey: BODY,
  }],
  [OPEN_LINK]: [
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      isRequired: true,
      labelKey: 'ADMIN_LABEL_TEXT',
      valueKey: BODY,
    },
    {
      componentKey: COMPONENT_TYPES.LINK_TEXT,
    },
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      labelKey: 'ADMIN_LABEL_URL',
      placeholderKey: 'ADMIN_QUEST_ACTION_INPUT_PLACEHOLDER_URL',
      valueKey: VALIDATION_VALUE,
    },
    {
      componentKey: COMPONENT_TYPES.CHANNEL_SELECT,
      valueKey: CHANNEL_ID,
    },
  ],
  [RETWEET]: [
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      isRequired: true,
      labelKey: 'ADMIN_LABEL_TEXT',
      valueKey: BODY,
    },
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      labelKey: 'ADMIN_LABEL_QUEST_RETWEETS',
      placeholderKey: 'ADMIN_LABEL_REQUIRED_AMOUNT',
      type: 'number',
      valueKey: VALUE_REQUIRED,
    },
    {
      componentKey: COMPONENT_TYPES.CHANNEL_SELECT,
      valueKey: CHANNEL_ID,
    },
  ],
  [SEA_OF_THIEVES_KILL_PLAYER_ID]: seaOfThievesConfig(SEA_OF_THIEVES_KILL_PLAYER_ID),
  [SEA_OF_THIEVES_KILL_SKELETON_ID]: seaOfThievesConfig(SEA_OF_THIEVES_KILL_SKELETON_ID),
  [SEA_OF_THIEVES_VISIT_ISLAND_ID]: seaOfThievesConfig(SEA_OF_THIEVES_VISIT_ISLAND_ID),
  [SHARE_FACEBOOK]: [
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      isRequired: true,
      labelKey: 'ADMIN_LABEL_TEXT',
      valueKey: BODY,
    },
    {
      componentKey: COMPONENT_TYPES.LINK_TEXT,
    },
    {
      componentKey: COMPONENT_TYPES.CHANNEL_SELECT,
      valueKey: CHANNEL_ID,
    },
  ],
  [VIEW_PANEL]: [
    {
      componentKey: COMPONENT_TYPES.PANEL_SELECT,
      labelKey: 'ADMIN_LABEL_QUEST_PANEL_TO_VIEW',
      valueKey: VALIDATION_VALUE,
    },
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      isRequired: true,
      labelKey: 'ADMIN_LABEL_TEXT',
      valueKey: BODY,
    },
    {
      componentKey: COMPONENT_TYPES.LINK_TEXT,
    },
    {
      componentKey: COMPONENT_TYPES.CHANNEL_SELECT,
      valueKey: CHANNEL_ID,
    },
  ],
  [VISIT_CHANNEL]: [
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      isRequired: true,
      labelKey: 'ADMIN_LABEL_TEXT',
      valueKey: BODY,
    },
    {
      allowAnyChannel: false,
      componentKey: COMPONENT_TYPES.CHANNEL_SELECT,
      valueKey: VALIDATION_VALUE,
    },
  ],
  [VISIT_TIME]: [
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      isRequired: true,
      labelKey: 'ADMIN_LABEL_TEXT',
      valueKey: BODY,
    },
    {
      componentKey: COMPONENT_TYPES.DATE_TIME_PICKER,
      dateSelectKey: 'ADMIN_LABEL_QUEST_START_DATE',
      timeSelectKey: 'ADMIN_LABEL_QUEST_START_TIME',
      valueKey: TIME_MINIMUM,
    },
    {
      componentKey: COMPONENT_TYPES.DATE_TIME_PICKER,
      dateSelectKey: 'ADMIN_LABEL_QUEST_END_DATE',
      timeSelectKey: 'ADMIN_LABEL_QUEST_END_TIME',
      valueKey: TIME_MAXIMUM,
    },
    {
      componentKey: COMPONENT_TYPES.CHANNEL_SELECT,
      valueKey: CHANNEL_ID,
    },
  ],
  [WATCH_ANY_VIDEO]: [
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      isRequired: true,
      labelKey: 'ADMIN_LABEL_TEXT',
      valueKey: BODY,
    },
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      labelKey: 'ADMIN_LABEL_QUEST_MINUTES_WATCHED',
      type: 'number',
      valueKey: VALUE_REQUIRED,
    },
    {
      componentKey: COMPONENT_TYPES.CHANNEL_SELECT,
      valueKey: CHANNEL_ID,
    },
  ],
  [WATCH_SPECIFIC_VIDEO]: [
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      isRequired: true,
      labelKey: 'ADMIN_LABEL_TEXT',
      valueKey: BODY,
    },
    {
      componentKey: COMPONENT_TYPES.LABEL,
      stringKey: 'ADMIN_LABEL_QUEST_VIDEO',
    },
    {
      componentKey: COMPONENT_TYPES.VIDEO_BUTTON,
      labelKey: 'ADMIN_LABEL_QUEST_VIDEO',
    },
    {
      componentKey: COMPONENT_TYPES.LINK_TEXT,
    },
    {
      componentKey: COMPONENT_TYPES.TEXT_INPUT,
      labelKey: 'ADMIN_LABEL_QUEST_MINUTES_WATCHED',
      type: 'number',
      valueKey: VALUE_REQUIRED,
    },
    {
      componentKey: COMPONENT_TYPES.CHANNEL_SELECT,
      valueKey: CHANNEL_ID,
    },
  ],
};

export default templates;
